import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const WebGLCanvas = () => {

  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);

  useEffect(() => {

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 0);
    rendererRef.current = renderer;

    mountRef.current.appendChild(renderer.domElement);

    const pointsMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 0.05 });
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });

    const pointsGeometry = new THREE.BufferGeometry();
    const linesGeometry = new THREE.BufferGeometry();

    const numPoints = 50;
    const positions = new Float32Array(numPoints * 3);
    const linePositions = [];

    for (let i = 0; i < numPoints; i++) {
      const x = Math.random() * 2 - 1;
      const y = Math.random() * 2 - 1;
      const z = Math.random() * 2 - 1;

      positions[i * 3] = x;
      positions[i * 3 + 1] = y;
      positions[i * 3 + 2] = z;

      for (let j = i + 1; j < numPoints; j++) {
        linePositions.push(x, y, z);
        linePositions.push(
          positions[j * 3],
          positions[j * 3 + 1],
          positions[j * 3 + 2]
        );
      }
    }

    pointsGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    linesGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(linePositions), 3));

    const pointsMesh = new THREE.Points(pointsGeometry, pointsMaterial);
    const linesMesh = new THREE.LineSegments(linesGeometry, lineMaterial);

    scene.add(pointsMesh);
    scene.add(linesMesh);

    camera.position.z = 2.5;

    const animate = () => {
      requestAnimationFrame(animate);

      scene.rotation.x += 0.001;
      scene.rotation.y += 0.001;

      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (rendererRef.current && rendererRef.current.domElement) {
        if (mountRef.current) {
          mountRef.current.removeChild(rendererRef.current.domElement);
        }
        rendererRef.current.dispose();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div ref={mountRef} />;
};

export default WebGLCanvas;

import React from 'react';
import Header from './components/Header';
import CircularImage from './components/CircularImage';
import Footer from './components/Footer';
import WebGLCanvas from './components/WebGLCanvas';
import { useNavigate } from 'react-router-dom';
import './App.css';

function App() {
  const navigate = useNavigate();
  const handleAlgebraLinealClick = () => {
    navigate('/pages/mat');
  };
  const handleOpenGLClick = () => {
    navigate('/pages/openGL');
  };


  return (
    <div className="bg-fixed" id="init">
      <Header />
      <div id="section-1" className="section section-1">
        <div className="content-overlay">
          <h1>Estudios en Inteligencia Artificial</h1>
        </div>
        <WebGLCanvas />
      </div>
      <div id="section-2" className="section section-2 section-plan">
        <ol>
          <li><strong><a onClick={handleOpenGLClick} style={{ cursor: 'pointer' }}>Caso de Estudio: OpenGL C++</a></strong></li>
          <ul>
            <li><strong>Computacion Grafica</strong></li>
          </ul>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Matemáticas y Teoría</a></strong>
            <p></p>
            <ul>
              <li><strong>Álgebra Lineal:</strong> Descomposición en valores singulares (SVD), Autovalores y autovectores, Espacios vectoriales y subespacios.</li>
              <li><strong>Cálculo:</strong> Optimización multivariable, Derivadas parciales y gradiente, Ecuaciones diferenciales y sistemas dinámicos.</li>
              <li><strong>Teoría de Probabilidades:</strong> Procesos estocásticos y cadenas de Markov, Inferencia bayesiana avanzada, Distribuciones multivariadas y teoría de copulas.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Teoría de la Información y Codificación</a></strong>
            <ul>
              <li><strong>Entropía de Shannon, codificación de Huffman.</strong></li>
              <li><strong>Compresión de datos:</strong> Compresión sin pérdida vs. con pérdida.</li>
              <li><strong>Teoría de codificación:</strong> Códigos correctores de errores, códigos de bloque, códigos convolucionales.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Optimización</a></strong>
            <ul>
              <li><strong>Optimización Convexa:</strong> Programación convexa, dualidad, KKT.</li>
              <li><strong>Optimización No Convexa:</strong> Técnicas para superar mínimos locales, algoritmos evolutivos.</li>
              <li><strong>Métodos de Monte Carlo:</strong> Integración numérica, métodos MCMC.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Machine Learning Avanzado</a></strong>
            <ul>
              <li><strong>Aprendizaje por Refuerzo Avanzado:</strong> Métodos de política basada en gradientes (REINFORCE, PPO), Aprendizaje profundo por refuerzo (Deep Q Networks, A3C), Algoritmos de planificación (Monte Carlo Tree Search, AlphaGo).</li>
              <li><strong>Modelos Probabilísticos Generativos:</strong> Modelos ocultos de Markov (HMM), modelos de mezcla de Gaussianas (GMM), Campos aleatorios de Markov (MRF) y redes bayesianas.</li>
              <li><strong>Aprendizaje Semi-supervisado y Auto-supervisado:</strong> Técnicas para aprovechar grandes volúmenes de datos no etiquetados.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Deep Learning Avanzado</a></strong>
            <ul>
              <li><strong>Modelos de Redes Neuronales Profundas (DNN):</strong> Técnicas de regularización avanzada (Dropout, Batch Normalization, Weight Decay), Arquitecturas profundas: Residual Networks (ResNet), DenseNet, EfficientNet.</li>
              <li><strong>Redes Neuronales Convolucionales (CNN):</strong> Redes convolucionales 3D para procesamiento de video, Redes Siamesas y Contrastivas.</li>
              <li><strong>Redes Neuronales Recurrentes (RNN):</strong> Arquitecturas de LSTM y GRU avanzadas, Redes de memoria a largo plazo (Memory Networks), Neural Turing Machines.</li>
              <li><strong>Transformers y Atención:</strong> Atención multicanal (Multi-head Attention), Transformers avanzados como GPT-3 y BERT, Modelos de generación de texto avanzados, como T5 y BLOOM.</li>
              <li><strong>Redes Generativas Adversarias (GAN):</strong> GANs condicionales (cGANs), StyleGAN, BigGAN, Aplicaciones avanzadas como generación de imágenes de alta resolución, síntesis de voz y música.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Procesamiento Avanzado de Lenguaje Natural (NLP)</a></strong>
            <ul>
              <li><strong>Modelos de Lenguaje:</strong> Modelos de lenguajes autoregresivos y enmascarados (BERT, GPT, XLNet), Modelos de lenguaje universal y embeddings avanzados (LASER, mBERT).</li>
              <li><strong>Análisis de Sentimiento y Resúmenes Automáticos:</strong> Técnicas avanzadas de extracción de características y modelado de temas.</li>
              <li><strong>Traducción Automática Neural:</strong> Modelos seq2seq con atención, Transformers para traducción, modelos multiidioma.</li>
              <li><strong>Entendimiento Semántico:</strong> Representación semántica profunda, redes de significado, y representación contextualizada.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Visión por Computadora</a></strong>
            <ul>
              <li><strong>Detección y Seguimiento de Objetos:</strong> Redes YOLOv5, DETR (Detection Transformer), Seguimiento visual en tiempo real, redes para video (3D CNN, ConvLSTM).</li>
              <li><strong>Segmentación Semántica y de Instancias:</strong> Modelos avanzados como DeepLab, Mask R-CNN, Segmentación basada en Transformers (SETR).</li>
              <li><strong>Visión Multimodal:</strong> Combinar datos de múltiples fuentes (visual, textual, etc.), Modelos como CLIP (Contrastive Language-Image Pre-Training).</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Métodos Avanzados de Optimización de Hiperparámetros</a></strong>
            <ul>
              <li><strong>Optimización Bayesiana:</strong> Técnicas como Gaussian Processes, Tree-structured Parzen Estimators (TPE).</li>
              <li><strong>AutoML:</strong> Frameworks para automatización de la búsqueda de modelos y optimización de hiperparámetros.</li>
              <li><strong>Modelos Ensamblados:</strong> Boosting, Bagging, Stacking.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Implementación en Producción a Gran Escala</a></strong>
            <ul>
              <li><strong>Servir Modelos a Gran Escala:</strong> Implementación en arquitecturas distribuidas (Spark MLlib, Dask), Uso de GPUs y TPUs para inferencia.</li>
              <li><strong>MLOps Avanzado:</strong> Pipeline de CI/CD para ML, gestión de experimentos con herramientas como MLflow, Monitorización de modelos en producción, detección de drift.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Investigación y Publicación</a></strong>
            <ul>
              <li><strong>Lectura y Crítica de Artículos Científicos:</strong> Revisión de papers avanzados en conferencias top (NeurIPS, ICML, CVPR), Técnicas de análisis crítico y revisión por pares.</li>
              <li><strong>Desarrollo de Nuevos Algoritmos:</strong> Identificación de gaps en la literatura, formulación de hipótesis y pruebas experimentales, Redacción de artículos y presentación en conferencias.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>IA Ética y Sostenibilidad</a></strong>
            <ul>
              <li><strong>Sesgo en IA:</strong> Detección y mitigación de sesgos, fairness en IA.</li>
              <li><strong>Privacidad y Seguridad:</strong> Técnicas de privacidad diferencial, ataques adversariales y defensa.</li>
              <li><strong>IA Sostenible:</strong> Eficiencia energética en modelos, reducción de la huella de carbono en entrenamiento de modelos.</li>
            </ul>
          </li>
          <li><strong><a onClick={handleAlgebraLinealClick} style={{ cursor: 'pointer' }}>Recursos y Lecturas</a></strong>
            <ul>
              <li><strong>Libros:</strong> "Pattern Recognition and Machine Learning" por Christopher Bishop, "Bayesian Reasoning and Machine Learning" por David Barber.</li>
              <li><strong>Cursos Online:</strong> "Deep Reinforcement Learning" por David Silver (UCL/DeepMind), "Advanced Machine Learning Specialization" en Coursera.</li>
              <li><strong>Herramientas:</strong> PyTorch Lightning, Hugging Face Transformers, Ray Tune para optimización avanzada.</li>
            </ul>
          </li>
        </ol>
      </div>
      <div id="section-3" className="section section-3">
        <p>Me estoy especializando en Inteligencia Artificial con foco en Visión por Computador.
          Me dedico a la innovación en “Inteligencia Artificial y Machine Learning”. Mi pasión es desarrollar
          algoritmos que permitan a las máquinas interpretar y procesar imágenes y videos, aplicando estas
          tecnologías para resolver retos complejos en diversas industrias. Comprometido con el aprendizaje continuo,
          busco contribuir al avance de soluciones que transformen procesos y generen nuevas oportunidades de negocio.</p>
        <CircularImage></CircularImage>
      </div>
      <div id='footer'>
        <Footer />
      </div>
    </div>
  );
}

export default App;

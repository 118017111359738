import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import HeaderContent from '../components/HeaderContent';
import './Mat.css';

const Mat = () => {
    useEffect(() => {
        const handleScroll = (event) => {
            const targetId = event.target.getAttribute('href');
            if (targetId && targetId.startsWith('#')) {
                event.preventDefault();
                const targetElement = document.querySelector(targetId);
                const offset = 80;
                const elementPosition = targetElement.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        };

        const navLinks = document.querySelectorAll('a[href^="#"]');
        navLinks.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            navLinks.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderContent />
            <div className="section section-mat">
                <h2 className='container'>Matemáticas y Teoría</h2>
                <div className="topic">
                    <Nav.Link href="#svd"><h4>Álgebra Lineal</h4></Nav.Link>
                    <ul>
                        <Nav.Link href="#svd"><li>Descomposición en valores singulares (SVD)</li></Nav.Link>
                        <Nav.Link href="#svd"><li>Autovalores y autovectores</li></Nav.Link>
                        <Nav.Link href="#svd"><li>Espacios vectoriales y subespacios</li></Nav.Link>
                    </ul>
                </div>
                <div className="topic">
                    <h4>Cálculo</h4>
                    <ul>
                        <Nav.Link href="#calculo"><li>Optimización multivariable</li></Nav.Link>
                        <Nav.Link href="#calculo"><li>Derivadas parciales y gradiente</li></Nav.Link>
                        <Nav.Link href="#calculo"><li>Ecuaciones diferenciales y sistemas dinámicos</li></Nav.Link>
                    </ul>
                </div>
                <div className="topic">
                    <h4>Teoría de Probabilidades</h4>
                    <ul>
                        <Nav.Link href="#probabilidad"><li>Procesos estocásticos y cadenas de Markov</li></Nav.Link>
                        <Nav.Link href="#probabilidad"><li>Inferencia bayesiana avanzada</li></Nav.Link>
                        <Nav.Link href="#probabilidad"><li>Distribuciones multivariadas y teoría de copulas</li></Nav.Link>
                    </ul>
                </div>
                <section id='svd'>
                    <div className='separator'></div>
                    <article className='algebra-lineal'>
                        <div>
                            <h3>Álgebra Lineal</h3>
                        </div>
                        <div className='container'>
                            <p>El Álgebra Lineal tiene aplicaciones fundamentales en el campo de la Inteligencia Artificial, incluyendo:</p>
                            <h5>Representación de Datos</h5>
                            <ul>
                                <li><strong>Vectores:</strong> Los datos en IA, como imágenes, textos o señales de audio, a menudo se representan como vectores. Cada componente del vector puede representar características específicas de los datos.</li>
                                <li><strong>Matrices:</strong> Los conjuntos de datos, como bases de datos o imágenes, pueden representarse como matrices, donde cada fila o columna corresponde a una instancia o característica.</li>
                            </ul>
                            <h5>Redes Neuronales</h5>
                            <p>Las operaciones fundamentales en las redes neuronales, como la propagación hacia adelante y el retroceso de errores, implican productos de matrices y vectores, lo que es esencialmente álgebra lineal. Por ejemplo, las conexiones entre capas en una red neuronal se pueden representar como matrices que transforman un vector de activaciones en otro.</p>

                            <h5>Transformaciones Lineales</h5>
                            <p>Las transformaciones lineales, como la rotación o la traslación, son operaciones que se aplican a los datos para ajustar modelos o interpretar características. Estas transformaciones se representan y manipulan mediante matrices.</p>

                            <h5>Descomposición de Matrices</h5>
                            <p>Técnicas como la descomposición en valores singulares (SVD) o la descomposición de Cholesky se utilizan para simplificar y resolver problemas complejos, como la reducción de dimensionalidad en modelos de aprendizaje automático.</p>

                            <h5>Optimización</h5>
                            <p>Muchos algoritmos de IA, como los métodos de aprendizaje automático, dependen de la optimización de funciones de costo. Estas funciones se modelan y se resuelven utilizando técnicas de álgebra lineal, como el cálculo de gradientes y la minimización de errores.</p>

                            <h5>Métodos de Valores Propios y Autovectores</h5>
                            <p>En la detección de patrones y la reducción de dimensionalidad (por ejemplo, Análisis de Componentes Principales o PCA), los valores propios y los autovectores de una matriz se utilizan para identificar las direcciones principales en los datos.</p>

                            <p>En resumen, el álgebra lineal proporciona las bases matemáticas necesarias para muchas operaciones y algoritmos utilizados en la inteligencia artificial, haciendo posible la manipulación de grandes volúmenes de datos y el entrenamiento de modelos complejos.</p>
                        </div>
                    </article>
                    <div className='separator'></div>
                    <article className='algebra-lineal'>
                        <div>
                            <h3>Descomposición en valores singulares (SVD)</h3>
                        </div>
                        <div className='container'>
                            <p>La Descomposición en Valores Singulares (SVD) es una técnica en álgebra lineal que descompone una matriz A en tres matrices: U, Σ, y V<sup>T</sup>, donde:</p>
                            <ul>
                                <li><strong>U:</strong> Una matriz ortogonal de dimensiones m x m.</li>
                                <li><strong>Σ:</strong> Una matriz diagonal de dimensiones m x n, con los valores singulares en la diagonal.</li>
                                <li><strong>V<sup>T</sup>:</strong> La transpuesta de una matriz ortogonal V de dimensiones n x n.</li>
                            </ul>
                            <p>La fórmula general es: A = U Σ V<sup>T</sup>.</p>
                            <p>Entonces podemos decir que las propiedades son que: </p>
                            <p>UU<sup>T</sup> = U<sup>T</sup>U = (I) Identidad </p>
                            <p>VV<sup>T</sup> = V<sup>T</sup>V = (I) Identidad </p>
                            <p>Σ es una matriz diagonal cuya entrada son valores singulares Σ<sup>1</sup> mayor igual a Σ<sup>2</sup>.....Σ<sup>n</sup> mayor igual a 0</p>
                            <p>Supongamos que tenemos nuestra matriz X, cada columna contiene datos, como por ejemplo pixeles de fotos de una caras, busquedas de peliculas en un sitio o paginas web en las que hacen click en algunos lados
                            </p>
                            <BlockMath>
                                {`
                                 X = \\begin{pmatrix}
                                 | & | & | \\\\
                                 x & x & x \\\\
                                 | & | & |
                                 \\end{pmatrix}
                                 `}
                            </BlockMath>
                            <br></br>
                            <BlockMath>
                                {`
                                 V = \\begin{pmatrix}
                                 | & | & | \\\\
                                 u₁ & u₂ & u₃ \\\\
                                 | & | & |
                                 \\end{pmatrix}
                                `}
                            </BlockMath>

                            <br></br>
                            <BlockMath>
                                {`
                                 U = \\begin{pmatrix}
                                 | & | & | \\\\
                                 u₁ & u₂ & u₃ \\\\
                                 | & | & |
                                 \\end{pmatrix}
                                 `}
                            </BlockMath>
                            <br></br>
                            <div style={{ borderBottom: 'solid 1px white' }}></div>
                            <br></br>
                            <p>En la matriz Sigma contiene los <strong style={{ color: 'green' }}>valores singulares diagonales</strong> y luego contiene ceros </p>
                            <BlockMath>
                                {`
                                 σ = \\begin{pmatrix}
                                 σ₁ & 0 & 0 \\\\
                                 0 & σ₂ & 0 \\\\
                                 0 & 0 & σ₃
                                 \\end{pmatrix}
                                 `}
                            </BlockMath>
                            <br></br>
                            <div style={{ borderBottom: 'solid 1px white' }}></div>
                            <br></br>
                            <p>Luego tenemos la matriz de los <strong style={{ color: 'green' }}> vectores singulares derechos </strong>
                                y esta matriz esta transpuesta</p>
                            <BlockMath>
                                {`
                                 Vt = \\begin{pmatrix}
                                 | & | & | \\\\
                                 v₁ & v₂ & v₃ \\\\
                                 | & | & |
                                 \\end{pmatrix}
                                 `}
                            </BlockMath>
                            <br></br>
                            <div style={{ borderBottom: 'solid 1px white' }}></div>
                            <br></br>
                            <p>Estos vectores <strong style={{ color: 'green' }}>U</strong> forman una base ortonormal para el espacio columna de <strong style={{ color: 'green' }}>X</strong>,
                                quiero decir que todos los vectores tienen norma 1,
                                y son perpendiculares entre si, tambien analogamente las columnas de <strong style={{ color: 'green' }}>V </strong>
                                forman una base ortonormal del espacio fila de <strong style={{ color: 'green' }}>X transpuesta</strong>,
                                por lo tanto <strong style={{ color: 'green' }}>Sigma</strong> contiene los elementos los datos que van decreciendo y nos anuncia que tan importante son cada elemento de esa base,
                                si quiero describir por ejemplo una imagen de un rostro, la primera columna de <strong style={{ color: 'green' }}>U</strong> va a ser un rostro representativo que va a ser la mas importante
                                que tengo para describir todos los datos, la segunda va a ser el segundo rostro mas representativo para representar mis datos, <strong style={{ color: 'green' }}>existe una jerarquia </strong>
                                y esto es muy importante para describir los datos.
                            </p>

                            <BlockMath>
                                {`
                                 X = \\begin{pmatrix}
                                 | & | & | \\\\
                                 x & x & x \\\\
                                 | & | & |
                                 \\end{pmatrix}= \\begin{pmatrix}
                                 | & | & | \\\\
                                 u₁ & u₂ & u₃ \\\\
                                 | & | & |
                                 \\end{pmatrix}= \\begin{pmatrix}
                                 σ₁ & 0 & 0 \\\\
                                 0 & σ₂ & 0 \\\\
                                 0 & 0 & σ₃
                                 \\end{pmatrix}= \\begin{pmatrix}
                                 | & | & | \\\\
                                 v₁ & v₂ & v₃ \\\\
                                 | & | & |
                                 \\end{pmatrix}T
                                 `}
                            </BlockMath>
                            <h3>En un ejercicio completo el calculo se veria de esta forma:</h3>
                            <div>
                                <p>Para encontrar los <strong style={{ color: 'green' }}>autovalores</strong> de la matriz <InlineMath math="A^T A" />, seguimos estos pasos:</p>
                                <p><b>Paso 1:</b> Calcular <InlineMath math="A^T A" />.</p>
                                <p>La matriz original <InlineMath math="A" /> es:</p>
                                <BlockMath math={`A = \\begin{pmatrix} 1 & 5 & 10 \\\\ 2 & 4 & 3 \\\\ 9 & 8 & -4 \\end{pmatrix}`} />
                                <p>La transpuesta de <InlineMath math="A" /> es:</p>
                                <BlockMath math={`A^T = \\begin{pmatrix} 1 & 2 & 9 \\\\ 5 & 4 & 8 \\\\ 10 & 3 & -4 \\end{pmatrix}`} />
                                <p>El producto de <InlineMath math="A^T A" /> es:</p>
                                <BlockMath math={`A^T A = \\begin{pmatrix} 86 & 84 & -31 \\\\ 84 & 90 & 2 \\\\ -31 & 2 & 125 \\end{pmatrix}`} />
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p><b>Paso 2:</b> Restar <InlineMath math="\lambda I" />.</p>
                                <p>La matriz identidad <InlineMath math="I" /> es:</p>
                                <BlockMath math={`I = \\begin{pmatrix} 1 & 0 & 0 \\\\ 0 & 1 & 0 \\\\ 0 & 0 & 1 \\end{pmatrix}`} />
                                <div>
                                    <p>La matriz identidad es una matriz cuadrada con unos en la diagonal principal y ceros en las demás posiciones. Se representa como \( I \) y tiene la propiedad de que cualquier matriz multiplicada por la identidad resulta en la misma matriz.</p>
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <h4>Explicacion sobre la matriz identidad:</h4>

                                    <p>Identidad 2x2:</p>
                                    <BlockMath math={`I = \\begin{pmatrix} 1 & 0 \\\\ 0 & 1 \\end{pmatrix}`} />

                                    <p>Identidad 3x3:</p>
                                    <BlockMath math={`I = \\begin{pmatrix} 1 & 0 & 0 \\\\ 0 & 1 & 0 \\\\ 0 & 0 & 1 \\end{pmatrix}`} />

                                    <p><strong>Propiedad:</strong></p>
                                    <p>
                                        Para cualquier matriz A de tamaño nxn:
                                    </p>
                                    <BlockMath math={`A \\times I = A`} />
                                    <BlockMath math={`I \\times A = A`} />

                                    <p>Esto significa que multiplicar cualquier matriz por la identidad no cambia el valor de la matriz.</p>
                                </div>
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>Para continuar restamos <InlineMath math="\lambda I" /> de <InlineMath math="A^T A" />:</p>
                                <BlockMath math={`A^T A - \\lambda I = \\begin{pmatrix} 86 - \\lambda & 84 & -31 \\\\ 84 & 90 - \\lambda & 2 \\\\ -31 & 2 & 125 - \\lambda \\end{pmatrix}`} />
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p><b>Paso 3:</b> Resolver el determinante <InlineMath math="\det(A^T A - \lambda I) = 0" />.</p>
                                <BlockMath math={`\\det\\begin{pmatrix} 86 - \\lambda & 84 & -31 \\\\ 84 & 90 - \\lambda & 2 \\\\ -31 & 2 & 125 - \\lambda \\end{pmatrix} = 0`} />
                                <p>Para calcular la determinante primero debemos entender que el determinante de la matriz es el producto de sus valores singulares, en este contexto de un polinomio,
                                    el calculo se realiza con sus valores propios (eigenvalores) y no directamente con los valores singulares.
                                </p>
                                <p>La matriz esta relacionado con un polinomio caracteristico de una matriz simetrica.</p>
                                <p>Para resolver esto:</p>
                                <ul>
                                    <li>La determinante de una matriz 3 x 3 se puede calcular usando esta formula</li>
                                    <br></br>
                                    <div style={{ textAlign: 'center' }}>
                                        <p><strong>det(A) = a (ei -fh) - b (di - fg) + c (dh - eg)</strong></p>
                                    </div>

                                    <p>Donde la matriz A es:</p>
                                    <BlockMath>
                                        {`
                                        A = \\begin{pmatrix}
                                        a & b & c \\\\
                                        d & e & f \\\\
                                        g & h & i
                                        \\end{pmatrix}
                                        `}
                                    </BlockMath>
                                    <p>Si aplicamos esta formular a la matriz 3 x 3 que tenemos:</p>
                                    <BlockMath math={`A^T A - \\lambda I = \\begin{pmatrix} 86 - \\lambda & 84 & -31 \\\\ 84 & 90 - \\lambda & 2 \\\\ -31 & 2 & 125 - \\lambda \\end{pmatrix}`} />
                                    <p>podemos calcular el determinante expandieolo, lo que nos llevaria a un polinomio cubico en λ</p>
                                    <li>
                                        La ecuacion cubica resultante sera:
                                        <div style={{ textAlign: 'center' }}>
                                            <p><strong>(86-λ)((90-λ)(125-λ)-(2)(2)) - 84(84(125-λ)-(-31)(2)) + (-31)(84(2)-(90−λ)(-31)) = 0</strong></p>
                                        </div>
                                    </li>
                                    <li>Después de simplificar este polinomio, obtendrás una ecuación cúbica que puedes resolver para encontrar los valores de λ, que son los eigenvalores de la matriz.</li>
                                    <li>Estos eigenvalores λ están relacionados con los valores singulares si estás considerando una matriz cuadrada simétrica, ya que los valores
                                        singulares serían las raíces cuadradas de los eigenvalores. El determinante de la matriz original se puede obtener multiplicando estos eigenvalores.</li>
                                    <br></br>
                                    <p><strong>Calcularmos los términos por pasos:</strong></p>
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <p> Primer término<strong> a(ei - fh)</strong></p>
                                    <BlockMath math={`(86 - \\lambda)\\left( (90 - \\lambda)(125 - \\lambda) - (2)(2) \\right)`} />
                                    <p>
                                        <strong>1. Primer termino</strong> (90 - λ)(125 - λ):
                                    </p>
                                    <BlockMath math={`(90 - \\lambda)(125 - \\lambda) = 11250 - 215\\lambda + \\lambda^2`} />
                                    <p>
                                        Ahora restamos 4 de ese resultado:
                                    </p>
                                    <BlockMath math={`11250 - 215\\lambda + \\lambda^2 - 4 = 11246 - 215\\lambda + \\lambda^2`} />
                                    <p>
                                        Ahora multiplicamos por (86 - λ):
                                    </p>
                                    <BlockMath math={`(86 - \\lambda)(11246 - 215\\lambda + \\lambda^2) = 86(11246) - 86(215\\lambda) + 86(\\lambda^2) - \\lambda(11246) + 215\\lambda^2 - \\lambda^3`} />
                                    <p>
                                        Esto se expande a:
                                    </p>
                                    <BlockMath math={`966156 - 18490\\lambda + 86\\lambda^2 - 11246\\lambda + 215\\lambda^2 - \\lambda^3`} />
                                    <p>
                                        Al simplificar:
                                    </p>
                                    <BlockMath math={`966156 - 29736\\lambda + 301\\lambda^2 - \\lambda^3`} />
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <p><strong>2. Segundo término: -b(di - fg)</strong></p>
                                    <BlockMath math={`-84\\left( 84(125 - \\lambda) - (-31)(2) \\right)`} />
                                    <p>
                                        Calculamos 84(125 - λ):
                                    </p>
                                    <BlockMath math={`84(125 - \\lambda) = 10500 - 84\\lambda`} />
                                    <p>
                                        Y luego sumamos 62 (resultado de -31(2)):
                                    </p>
                                    <BlockMath math={`10500 - 84\\lambda + 62 = 10562 - 84\\lambda`} />
                                    <p>
                                        Multiplicamos por -84:
                                    </p>
                                    <BlockMath math={`-84(10562 - 84\\lambda) = -886608 + 7056\\lambda`} />
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <p><strong>3. Tercer término: c(dh - eg)</strong></p>
                                    <BlockMath math={`-31(84(2) - (90 - \\lambda)(-31))`} />
                                    <p>
                                        Primero calculamos 84(2):
                                    </p>
                                    <BlockMath math={`84(2) = 168`} />
                                    <p>
                                        Y luego (90 - λ)(-31):
                                    </p>
                                    <BlockMath math={`(90 - \\lambda)(-31) = -2790 + 31\\lambda`} />
                                    <p>
                                        Sumamos los resultados:
                                    </p>
                                    <BlockMath math={`168 + 2790 - 31\\lambda = 2958 - 31\\lambda`} />
                                    <p>
                                        Multiplicamos por -31:
                                    </p>
                                    <BlockMath math={`-31(2958 - 31\\lambda) = -91698 + 961\\lambda`} />
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <p><strong>Paso 4: Sumar todos los términos</strong></p>

                                    <p>
                                        Ahora sumamos todos los términos:
                                    </p>
                                    <BlockMath math={`966156 - 29736\\lambda + 301\\lambda^2 - \\lambda^3 + (-886608 + 7056\\lambda) + (-91698 + 961\\lambda)`} />
                                    <p>
                                        Esto se simplifica a:
                                    </p>
                                    <BlockMath math={`-\\lambda^3 + 301\\lambda^2 - 21719\\lambda - 11750`} />
                                    <br></br>
                                    <div style={{ borderBottom: 'solid 1px white' }}></div>
                                    <br></br>
                                    <p><strong>Paso 5: Ecuación característica</strong></p>
                                    <p>
                                        La ecuación característica es:
                                    </p>
                                    <BlockMath math={`-\\lambda^3 + 301\\lambda^2 - 21719\\lambda - 11750 = 0`} />
                                    <p>
                                        Esta es la ecuación que debes resolver para encontrar los eigenvalores de la matriz.
                                    </p>

                                </ul>
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>Obtenemos el polinomio característico cúbico. Las soluciones a este polinomio son los <strong style={{ color: 'green' }}>autovalores</strong>:</p>
                                <BlockMath math={`\\lambda_1 \\approx 197.88, \\lambda_2 \\approx 71.55, \\lambda_3 \\approx 31.57`} />
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p><b>Paso 4:</b> Los valores singulares son las raíces cuadradas de los <strong style={{ color: 'green' }}>autovalores</strong>:</p>
                                <BlockMath math={`\\sigma_1 = \\sqrt{197.88} \\approx 14.07, \\sigma_2 = \\sqrt{71.55} \\approx 8.46, \\sigma_3 = \\sqrt{31.57} \\approx 5.62`} />
                                <div>
                                    <p>Formación de la matriz <InlineMath math="\\Sigma" /> en la descomposición SVD con valores singulares <InlineMath math="Sigma_i" /> ya que son las raíces cuadradas de los autovalores:</p>
                                    <BlockMath math={"\\sigma_1 = \\sqrt{197.88} \\approx 14.07"} />
                                    <BlockMath math={"\\sigma_2 = \\sqrt{71.55} \\approx 8.46"} />
                                    <BlockMath math={"\\sigma_3 = \\sqrt{31.57} \\approx 5.62"} />

                                    <p>La matriz Sigma es una matriz diagonal formada por estos valores singulares:</p>
                                    <BlockMath math={"\\Sigma = \\begin{pmatrix} 14.07 & 0 & 0 \\\\ 0 & 8.46 & 0 \\\\ 0 & 0 & 5.62 \\end{pmatrix}"} />

                                    <p>Esta matriz es parte de la descomposición en valores singulares (SVD) junto con las matrices U y <InlineMath math="V^T" />.</p>
                                </div>
                            </div>
                            <div>
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p><b>Paso 5:</b> Calcular los vectores singulares izquierdos (columnas de U).</p>
                                <p>Para calcular los vectores singulares izquierdos, usamos la relación:</p>
                                <BlockMath math={`A \\mathbf{v_i} = \\sigma_i \\mathbf{u_i}`} />
                                <p>
                                    donde <InlineMath math={"\\mathbf{v_{i}}"} /> es el <strong style={{ color: 'yellow' }}>autovector</strong> correspondiente al valor singular <InlineMath math={"\\sigma_{i}"} />, y <InlineMath math={"\\mathbf{u_{i}}"} /> es el vector singular izquierdo.
                                </p>
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>Para: <InlineMath math="\\sigma_1 = 14.07" />, resolvemos:</p>
                                <BlockMath math={`A \\mathbf{v_1} = 14.07 \\mathbf{u_1}`} />
                                <p>El resultado nos da aproximadamente:</p>
                                <BlockMath math={`\\mathbf{u_1} = \\begin{pmatrix} 0.32 \\\\ 0.44 \\\\ 0.83 \\end{pmatrix}`} />
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>Para: <InlineMath math="\\sigma_2 = 8.46" />, resolvemos:</p>
                                <BlockMath math={`A \\mathbf{v_2} = 8.46 \\mathbf{u_2}`} />
                                <p>El resultado es:</p>
                                <BlockMath math={`\\mathbf{u_2} = \\begin{pmatrix} 0.54 \\\\ -0.81 \\\\ 0.25 \\end{pmatrix}`} />
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>Para: <InlineMath math="\\sigma_3 = 5.62" />, resolvemos:</p>
                                <BlockMath math={`A \\mathbf{v_3} = 5.62 \\mathbf{u_3}`} />
                                <p>El resultado es:</p>
                                <BlockMath math={`\\mathbf{u_3} = \\begin{pmatrix} 0.78 \\\\ 0.39 \\\\ -0.49 \\end{pmatrix}`} />
                                <p>Por lo tanto, la matriz U es:</p>
                                <BlockMath math={`U = \\begin{pmatrix} 0.32 & 0.54 & 0.78 \\\\ 0.44 & -0.81 & 0.39 \\\\ 0.83 & 0.25 & -0.49 \\end{pmatrix}`} />

                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>

                                <div>
                                    <p>Calcular <InlineMath math="V^T" /> en la descomposición en valores singulares (SVD)</p>

                                    <p>La matriz original <InlineMath math="A" /> es:</p>
                                    <BlockMath math={"A = \\begin{pmatrix} 1 & 5 & 10 \\\\ 2 & 4 & 3 \\\\ 9 & 8 & -4 \\end{pmatrix}"} />

                                    <p>Primero, calculamos <InlineMath math="A^T A" />:</p>
                                    <p>La transpuesta de <InlineMath math="A" /> es:</p>
                                    <BlockMath math={"A^T = \\begin{pmatrix} 1 & 2 & 9 \\\\ 5 & 4 & 8 \\\\ 10 & 3 & -4 \\end{pmatrix}"} />

                                    <p>Ahora calculamos el producto <InlineMath math="A^T A" />:</p>
                                    <BlockMath math={"A^T A = \\begin{pmatrix} 86 & 84 & -31 \\\\ 84 & 90 & 2 \\\\ -31 & 2 & 125 \\end{pmatrix}"} />

                                    <p>
                                        A continuación, resolvemos la ecuación característica para encontrar los autovalores de <InlineMath math="A^T A" />. Restamos <InlineMath math="\\lambda I" /> de la matriz <InlineMath math="A^T A" />:
                                    </p>
                                    <BlockMath math={"A^T A - \\lambda I = \\begin{pmatrix} 86 - \\lambda & 84 & -31 \\\\ 84 & 90 - \\lambda & 2 \\\\ -31 & 2 & 125 - \\lambda \\end{pmatrix}"} />

                                    <p>Resolvemos el determinante <InlineMath math="\\det(A^T A - λI) = 0" /> para obtener los autovalores:</p>
                                    <BlockMath math={"\\lambda_1 \\approx 197.88, \\lambda_2 \\approx 71.55, \\lambda_3 \\approx 31.57"} />

                                    <p>A continuación, calculamos los autovectores <InlineMath math="v_i" /> correspondientes a cada <InlineMath math="\\lambda_i" />, resolviendo:</p>
                                    <BlockMath math={"(A^T A - \\lambda_i I) v_i = 0"} />

                                    <p>Estos autovectores forman las columnas de la matriz <InlineMath math="V" />. Finalmente, la transposición de esta matriz nos da <InlineMath math="V^T" />.</p>

                                    <p>La matriz <InlineMath math="V^T" /> es:</p>
                                    <BlockMath math={"V^T = \\begin{pmatrix} 0.63 & 0.69 & -0.36 \\\\ 0.73 & -0.52 & 0.45 \\\\ 0.27 & 0.50 & 0.82 \\end{pmatrix}"} />

                                    <p>De esta manera, hemos obtenido la matriz <InlineMath math="V^T" /> que es parte de la descomposición en valores singulares de la matriz original.</p>
                                </div>
                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                                <p>La descomposición en valores singulares de <InlineMath math="A" /> es:</p>
                                <p>En términos de <InlineMath math="U" />, <InlineMath math="Sigma" />, y <InlineMath math="V^T" />:</p>
                                <BlockMath math={`A = U \\Sigma V^T`} />
                                <p>donde:</p>
                                <BlockMath math={`U = \\begin{pmatrix} 0.32 & 0.54 & 0.78 \\\\ 0.44 & -0.81 & 0.39 \\\\ 0.83 & 0.25 & -0.49 \\end{pmatrix}Sigma = \\begin{pmatrix} 14.07 & 0 & 0 \\\\ 0 & 8.46 & 0 \\\\ 0 & 0 & 5.62 \\end{pmatrix} V^T = \\begin{pmatrix} 0.63 & 0.69 & -0.36 \\\\ 0.73 & -0.52 & 0.45 \\\\ 0.27 & 0.50 & 0.82 \\end{pmatrix}`} />

                                <br></br>
                                <div style={{ borderBottom: 'solid 1px white' }}></div>
                                <br></br>
                            </div>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Grafica en X y σ</h4>
                            <br></br>
                            <div className='img-container-1'>
                                <video width="600" controls>
                                    <source src="/assets/videos/svd_1.mp4" type="video/mp4" />
                                    Tu navegador no soporta la etiqueta de video.
                                </video>
                            </div>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Grafica en U y Vt</h4>
                            <br></br>
                            <div className='img-container-1'>
                                <video width="600" controls>
                                    <source src="/assets/videos/svd_2.mp4" type="video/mp4" />
                                    Tu navegador no soporta la etiqueta de video.
                                </video>
                            </div>
                            <br></br>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Resumen de la grafica</h4>
                            <ul>
                                <li>Buscamos un sistema de coordenadas para representar los vectores de X de forma mas eficiente.</li>
                                <li>Calculamos una sola direccion que maximice simultaneamente la prediccion de todos los vectores.</li>
                                <li>La direccion dominante es el primer vector (columna) de la matriz U que representa el vector singular izquierdo.</li>
                                <li>Graficamos el primer vector del singular izquierdo y es una base del espacio columna, tambien se escalo en base a sigma, basicamente se multiplica cada valor del vector por su correspondiente valor de sigma.</li>
                                <li>Las columnas de los vectores singulares izquierdos son ortogonales, lo mismo pasa si graficamos los vectores singulares derechos, cualquier de estos vectores se pueden escalar por sigma.</li>
                            </ul>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Análisis Visual de la Gráfica Resultante</h4>
                            <p>La gráfica en 3D muestra varios componentes clave de la descomposición SVD:</p>
                            <ul>
                                <li>Puntos <strong style={{ color: 'red' }}>rojos</strong>: Representan los datos originales de la matriz <InlineMath math="X" /> proyectados en el espacio tridimensional.</li>
                                <li>Vectores singulares <strong style={{ color: 'magenta' }}>magenta</strong>: Las flechas largas y de color magenta representan los vectores singulares escalados por los valores singulares correspondientes. Estos vectores muestran las direcciones principales de variabilidad en los datos.</li>
                                <li>Puntos<strong style={{ color: 'green' }}> verdes</strong> y <strong style={{ color: 'blue' }}>azules</strong>: Representan los vectores de las matrices <InlineMath math="U" /> y <InlineMath math="V^T" />, respectivamente, que capturan las características clave de los datos.</li>
                            </ul>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Interpretación de los Puntos de la Matriz <InlineMath math="X" /></h4>
                            <p>Los puntos rojos distribuidos en el gráfico corresponden a los datos originales de la matriz <InlineMath math="X" />. Esta matriz puede estar compuesta por variables correlacionadas, lo que da lugar a una nube de puntos que muestra la relación entre ellas.</p>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Vectores Singulares</h4>
                            <p>Los vectores singulares (flechas magenta) son particularmente importantes para comprender la descomposición SVD. Estas flechas representan los vectores de la matriz <InlineMath math="U" />, que están escalados por los valores singulares <InlineMath math="\Sigma" />.</p>
                            <ul>
                                <li><strong>Primer vector singular (<InlineMath math="\Sigma \cdot U[:,0]" />):</strong> Este vector es el más largo de los tres y apunta en la dirección donde la mayor parte de la variabilidad de los datos está presente. Esto indica que esta dirección contiene la mayor cantidad de información sobre los datos originales.</li>
                                <li><strong>Segundo vector singular (<InlineMath math="\Sigma \cdot U[:,1]" />):</strong> Es más corto que el primero, lo que sugiere que la variabilidad en esta dirección es menor, pero sigue siendo significativa.</li>
                                <li><strong>Tercer vector singular (<InlineMath math="\Sigma \cdot U[:,2]" />):</strong> Este vector es el más corto, lo que indica que hay muy poca variabilidad en esta dirección. En muchos casos, esta tercera dirección podría representar una componente de ruido o redundancia en los datos.</li>
                            </ul>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Matriz <InlineMath math="U" /> y <InlineMath math="V^T" /></h4>
                            <p>Los <strong>puntos verdes</strong> corresponden a la matriz <InlineMath math="U" /> y representan las direcciones ortogonales que describen cómo se relacionan las filas de la matriz original con los valores singulares. Están normalizadas y ayudan a reconstruir la estructura interna de los datos.</p>
                            <p>Los <strong>puntos azules</strong> corresponden a la matriz <InlineMath math="V^T" />, que describe la relación entre las columnas de la matriz <InlineMath math="X" />. Al igual que <InlineMath math="U" />, estos vectores son ortogonales entre sí y son esenciales para reconstruir <InlineMath math="X" /> desde una versión simplificada.</p>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Importancia de los Valores Singulares</h4>
                            <p>La longitud de los vectores magenta está directamente relacionada con los valores singulares. Estos valores nos indican cuánta información se captura en cada componente.</p>
                            <ul>
                                <li><strong>Primer valor singular:</strong> Es el más grande y contiene la mayor parte de la información.</li>
                                <li><strong>Segundo valor singular:</strong> Aunque menor que el primero, aún captura una cantidad significativa de información.</li>
                                <li><strong>Tercer valor singular:</strong> Es muy pequeño y podría representar ruido o redundancia.</li>
                            </ul>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Aplicación de la Reducción de Dimensionalidad</h4>
                            <p>Podemos observar que el primer vector singular es significativamente más largo que los otros dos. Esto sugiere que los datos podrían comprimirse eficientemente en un espacio de menor dimensionalidad, probablemente 2D en lugar de 3D, sin perder mucha información.</p>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Conclusión sobre el Gráfico</h4>
                            <p>Esta visualización del SVD muestra claramente cómo los valores singulares y los vectores ortogonales de las matrices <InlineMath math="U" /> y <InlineMath math="V^T" /> pueden descomponer la estructura de los datos originales.</p>
                            <p>Los vectores singulares más largos capturan la mayor parte de la variabilidad, lo que sugiere que el conjunto de datos puede simplificarse sin una gran pérdida de información. En aplicaciones como la compresión de imágenes o motores de recomendación, solo se retendrían los valores singulares más grandes, reduciendo significativamente la complejidad del modelo.</p>
                            <br></br>
                            <h4 style={{ borderBottom: 'solid 1px white' }}>Ejemplos y Casos de Uso</h4>
                            <ul>
                                <li><strong>Compresión de imágenes:</strong> Mostrar cómo una imagen puede aproximarse con solo los primeros valores singulares.</li>
                                <li><strong>Recomendación de productos:</strong> Cómo el SVD identifica patrones en grandes conjuntos de datos y recomienda productos basados en preferencias.</li>
                                <li><strong>Análisis de ruido:</strong> Explicar cómo los valores singulares más pequeños pueden representar ruido, y cómo eliminarlos puede mejorar el análisis.</li>
                            </ul>
                            <br></br>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>Introduccion_SVD.ipynb</a></p>
                            <br></br>
                            <h5>Aplicaciones principales:</h5>
                            <p>En la Inteligencia Artificial, SVD se usa para:</p>
                            <ul>
                                <li><strong>Reducción de Dimensionalidad:</strong> Como en el análisis de componentes principales (PCA).</li>
                                <li><strong>Sistemas de Recomendación:</strong> Para descomponer matrices de usuarios-productos y encontrar patrones.</li>
                                <li><strong>Compresión de Imágenes:</strong> Para reducir el tamaño de las imágenes sin perder demasiada calidad.</li>
                            </ul>
                            <br></br>
                            <h5>Ejemplo Práctico 1: Matriz 3x3</h5>
                            <p>Consideremos una matriz A de 3 x 3:</p>
                            <BlockMath>
                                {`
            A = \\begin{pmatrix}
            1 & 2 & 3 \\\\
            4 & 5 & 6 \\\\
            7 & 8 & 9
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Su descomposición en valores singulares produce:</p>
                            <BlockMath>
                                {`
            U = \\begin{pmatrix}
            -0.214837 & 0.887231 & 0.408248 \\\\
            -0.520587 & 0.249644 & -0.816497 \\\\
            -0.826338 & -0.387942 & 0.408248
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            \\Sigma = \\begin{pmatrix}
            16.8481 & 0 & 0 \\\\
            0 & 1.0684 & 0 \\\\
            0 & 0 & 0
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            V^T = \\begin{pmatrix}
            -0.479671 & -0.572368 & -0.665064 \\\\
            0.776691 & 0.0756865 & -0.625318 \\\\
            -0.408248 & 0.816497 & -0.408248
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Multiplicando U, Σ, y V<sup>T</sup>, se puede recuperar la matriz original A.</p>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>svd_1.ipynb</a></p>
                            <br></br>
                            <h5>Ejemplo Práctico 2: Matriz 4x4</h5>
                            <p>Consideremos una matriz A de 4 x 4:</p>
                            <BlockMath>
                                {`
            A = \\begin{pmatrix}
            4 & 1 & 3 & 5 \\\\
            2 & 6 & 4 & 8 \\\\
            1 & 5 & 7 & 3 \\\\
            5 & 2 & 1 & 9
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Su descomposición en valores singulares produce:</p>
                            <BlockMath>
                                {`
            U = \\begin{pmatrix}
            -0.4287 & 0.8051 & 0.1595 & -0.3885 \\\\
            -0.6532 & 0.0178 & -0.6335 & 0.4158 \\\\
            -0.4245 & -0.4192 & 0.7424 & 0.3153 \\\\
            -0.4674 & -0.4208 & -0.1143 & -0.7687
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            \\Sigma = \\begin{pmatrix}
            15.8416 & 0 & 0 & 0 \\\\
            0 & 6.7741 & 0 & 0 \\\\
            0 & 0 & 3.2875 & 0 \\\\
            0 & 0 & 0 & 1.1206
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            V^T = \\begin{pmatrix}
            -0.3170 & -0.7315 & 0.3843 & 0.4635 \\\\
            -0.5368 & 0.1921 & -0.8190 & 0.0454 \\\\
            0.5191 & -0.4180 & -0.2260 & 0.7094 \\\\
            0.5963 & 0.5012 & 0.3543 & 0.5181
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Multiplicando U, Σ, y V<sup>T</sup>, se puede recuperar la matriz original A.</p>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>svd_2.ipynb</a></p>
                            <br></br>
                            <h5>Ejemplo Práctico 3: Matriz 5x5</h5>
                            <p>Consideremos una matriz A de 5 x 5:</p>
                            <BlockMath>
                                {`
            A = \\begin{pmatrix}
            3 & 7 & 2 & 5 & 1 \\\\
            6 & 1 & 4 & 2 & 8 \\\\
            7 & 4 & 1 & 6 & 3 \\\\
            5 & 2 & 9 & 7 & 4 \\\\
            4 & 8 & 3 & 5 & 6
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Su descomposición en valores singulares produce:</p>
                            <BlockMath>
                                {`
            U = \\begin{pmatrix}
            -0.3760 & 0.7592 & -0.3706 & 0.1989 & 0.3297 \\\\
            -0.5320 & -0.1983 & 0.2431 & 0.7725 & 0.1932 \\\\
            -0.5103 & -0.4872 & -0.6968 & -0.0576 & -0.0173 \\\\
            -0.4318 & -0.2064 & 0.4842 & -0.3944 & 0.6464 \\\\
            -0.3386 & -0.3124 & 0.3332 & -0.4349 & -0.7220
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            \\Sigma = \\begin{pmatrix}
            21.2298 & 0 & 0 & 0 & 0 \\\\
            0 & 10.5465 & 0 & 0 & 0 \\\\
            0 & 0 & 8.7324 & 0 & 0 \\\\
            0 & 0 & 0 & 5.1647 & 0 \\\\
            0 & 0 & 0 & 0 & 1.3752
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            V^T = \\begin{pmatrix}
            -0.2575 & -0.6843 & 0.6016 & 0.1364 & -0.2941 \\\\
            0.6471 & 0.0692 & -0.1106 & 0.7448 & -0.0722 \\\\
            -0.5764 & 0.4574 & -0.2654 & 0.3436 & 0.4881 \\\\
            0.2583 & 0.5731 & 0.4799 & -0.2654 & -0.5463 \\\\
            0.3792 & -0.0367 & -0.5611 & 0.5061 & 0.5212
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Multiplicando U, Σ, y V<sup>T</sup>, se puede recuperar la matriz original A.</p>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>svd_3.ipynb</a></p>
                            <br></br>
                            <h5>Ejemplo Práctico 4: Matriz 6x6</h5>
                            <p>Consideremos una matriz A de 6 x 6:</p>
                            <BlockMath>
                                {`
            A = \\begin{pmatrix}
            1 & 2 & 3 & 4 & 5 & 6 \\\\
            7 & 8 & 9 & 10 & 11 & 12 \\\\
            13 & 14 & 15 & 16 & 17 & 18 \\\\
            19 & 20 & 21 & 22 & 23 & 24 \\\\
            25 & 26 & 27 & 28 & 29 & 30 \\\\
            31 & 32 & 33 & 34 & 35 & 36
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Su descomposición en valores singulares produce:</p>
                            <BlockMath>
                                {`
            U = \\begin{pmatrix}
            -0.0321 & 0.9932 & 0.1112 & 0.0175 & -0.0038 & 0.0012 \\\\
            -0.0731 & 0.0960 & -0.5301 & -0.7142 & 0.3832 & -0.1946 \\\\
            -0.1142 & 0.0321 & -0.7784 & 0.5673 & -0.2146 & 0.0597 \\\\
            -0.1553 & 0.0214 & 0.1408 & 0.4012 & 0.8225 & -0.3297 \\\\
            -0.1964 & 0.0168 & 0.2680 & -0.1872 & -0.1963 & 0.8897 \\\\
            -0.2375 & 0.0143 & 0.4034 & -0.0362 & -0.4712 & -0.0464
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            \\Sigma = \\begin{pmatrix}
            91.0959 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 4.6166 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 0.3395 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0.0000 & 0 & 0 \\\\
            0 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0 & 0 & 0
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            V^T = \\begin{pmatrix}
            -0.0321 & -0.0731 & -0.1142 & -0.1553 & -0.1964 & -0.2375 \\\\
            -0.9932 & -0.0960 & -0.0321 & -0.0214 & -0.0168 & -0.0143 \\\\
            0.1112 & -0.5301 & -0.7784 & 0.1408 & 0.2680 & 0.4034 \\\\
            -0.0175 & -0.7142 & 0.5673 & 0.4012 & -0.1872 & -0.0362 \\\\
            0.0038 & 0.3832 & -0.2146 & 0.8225 & -0.1963 & -0.4712 \\\\
            -0.0012 & -0.1946 & 0.0597 & -0.3297 & 0.8897 & -0.0464
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Multiplicando U, Σ, y V<sup>T</sup>, se puede recuperar la matriz original A.</p>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>svd_4.ipynb</a></p>
                            <br></br>
                            <h5>Ejemplo Práctico 5: Matriz 7x7</h5>
                            <p>Consideremos una matriz A de 7 x 7:</p>
                            <BlockMath>
                                {`
            A = \\begin{pmatrix}
            3 & 6 & 9 & 12 & 15 & 18 & 21 \\\\
            4 & 8 & 12 & 16 & 20 & 24 & 28 \\\\
            5 & 10 & 15 & 20 & 25 & 30 & 35 \\\\
            6 & 12 & 18 & 24 & 30 & 36 & 42 \\\\
            7 & 14 & 21 & 28 & 35 & 42 & 49 \\\\
            8 & 16 & 24 & 32 & 40 & 48 & 56 \\\\
            9 & 18 & 27 & 36 & 45 & 54 & 63
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Su descomposición en valores singulares produce:</p>
                            <BlockMath>
                                {`
            U = \\begin{pmatrix}
            -0.1178 & 0.9788 & 0.1620 & -0.0843 & -0.0424 & -0.0061 & 0.0014 \\\\
            -0.1562 & 0.0444 & 0.6806 & 0.6987 & -0.1473 & -0.0850 & 0.0331 \\\\
            -0.1946 & 0.0032 & -0.5016 & 0.7133 & 0.3734 & -0.0314 & -0.1801 \\\\
            -0.2329 & -0.0121 & -0.3468 & 0.1755 & -0.4357 & 0.5487 & 0.5903 \\\\
            -0.2712 & -0.0207 & -0.2744 & -0.3845 & 0.4815 & -0.4079 & 0.5552 \\\\
            -0.3095 & -0.0257 & -0.2064 & -0.4845 & -0.2863 & -0.7116 & -0.2447 \\\\
            -0.3478 & -0.0283 & -0.1533 & -0.4292 & -0.1053 & -0.3324 & -0.6756
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            \\Sigma = \\begin{pmatrix}
            124.5884 & 0 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 11.2738 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 1.4537 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0.0000 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0 & 0 & 0 & 0 \\\\
            0 & 0 & 0 & 0 & 0 & 0 & 0
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <BlockMath>
                                {`
            V^T = \\begin{pmatrix}
            -0.1178 & -0.1562 & -0.1946 & -0.2329 & -0.2712 & -0.3095 & -0.3478 \\\\
            -0.9788 & 0.0444 & 0.0032 & -0.0121 & -0.0207 & -0.0257 & -0.0283 \\\\
            -0.1620 & 0.6806 & -0.5016 & -0.3468 & -0.2744 & -0.2064 & -0.1533 \\\\
            0.0843 & 0.6987 & 0.7133 & 0.1755 & -0.3845 & -0.4845 & -0.4292 \\\\
            0.0424 & -0.1473 & 0.3734 & -0.4357 & 0.4815 & -0.2863 & -0.1053 \\\\
            0.0061 & -0.0850 & -0.0314 & 0.5487 & -0.4079 & -0.7116 & -0.3324 \\\\
            -0.0014 & 0.0331 & -0.1801 & 0.5903 & 0.5552 & -0.2447 & -0.6756
            \\end{pmatrix}
            `}
                            </BlockMath>
                            <p>Multiplicando U, Σ, y V<sup>T</sup>, se puede recuperar la matriz original A.</p>
                            <p>Link al ejercicio: <a href='https://colab.research.google.com/drive/1mxnFCk5ABkyuzCRTtaUTSOfHW9z0dFtq?authuser=0#scrollTo=oI6CL6aTxwBV'>svd_5.ipynb</a></p>
                            <br></br>
                            <br></br>
                        </div>
                        <div>
                            <h3>Tabla de Símbolos Matemáticos para SVD</h3>
                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: "1px solid black", padding: "10px" }}>Símbolo</th>
                                        <th style={{ border: "1px solid black", padding: "10px" }}>Descripción</th>
                                        <th style={{ border: "1px solid black", padding: "10px" }}>Explicación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="A" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz de entrada</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>La matriz original que se descompone en SVD.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="U" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz de vectores singulares izquierdos</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Contiene los vectores singulares izquierdos, ortogonales entre sí.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\Sigma" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz diagonal de valores singulares</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Contiene los valores singulares en la diagonal.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="V" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz de vectores singulares derechos</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Contiene los vectores singulares derechos, ortogonales entre sí.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="V^T" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Transpuesta de la matriz V</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Es la transpuesta de V, aparece en la descomposición SVD.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\sigma_i" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Valores singulares</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Son las raíces cuadradas de los autovalores de A<sup>T</sup>A .</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\lambda_i" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Autovalores</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Los valores propios o eigenvalores de A<sup>T</sup>A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="v_i" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Autovectores</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Los vectores propios de A<sup>T</sup>A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="A^T" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz transpuesta</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>La transpuesta de la matriz A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="A^T A" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Producto de A<sup>T</sup> con A</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Se usa para encontrar los autovalores y autovectores de A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="I" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz identidad</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz diagonal con unos en la diagonal principal.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\det(A)" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Determinante de la matriz A</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Producto de los valores propios de A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="|A|" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Norma de la matriz A </td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Una medida del tamaño o longitud de la matriz A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="(A^T A - \lambda I)" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Matriz característica</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Se usa para encontrar los autovalores.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\mathbb{R}^n" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Espacio vectorial real</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Espacio que contiene vectores de dimensión n.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\mathbf{0}" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector o matriz nula</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector o matriz donde todos los elementos son ceros.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\mathbf{e}_i" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector estándar</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector con un 1 en la posición i y ceros en las demás.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\text{rank}(A)" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Rango de la matriz A</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Número de vectores linealmente independientes en la matriz A.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\mathbf{x}" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector columna</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Vector que se usa para representar soluciones o coordenadas.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\mathbf{x}^T" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Transpuesta de un vector columna</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Convierte un vector columna en un vector fila.</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid black", padding: "10px" }}><InlineMath math="\langle \mathbf{u}, \mathbf{v} \rangle" /></td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Producto interno o punto entre dos vectores</td>
                                        <td style={{ border: "1px solid black", padding: "10px" }}>Mide la similitud entre dos vectores.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </article>
                </section>
            </div>

        </>
    );
}

export default Mat;

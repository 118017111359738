import React from 'react';
import './Footer.css';
import facebookIcon from '../assets/images/facebook.png';
import twitterIcon from '../assets/images/twitter.png';
import linkedinIcon from '../assets/images/linkedin.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 AI Knowledge Hub. All Rights Reserved.</p>
        <p>Follow us on:</p>
        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/in/matias-carrion-ab893628/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../assets/images/photo.jpeg'
import './CircularImage.css'

function CircularImage() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <img className="rounded-circle image-1" src={image} alt="photo" />
    </div>
  );
}

export default CircularImage;
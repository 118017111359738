import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import HeaderContent from '../components/HeaderContent';
import './Openg.css';

const Openg = () => {
    useEffect(() => {
        const handleScroll = (event) => {
            const targetId = event.target.getAttribute('href');
            if (targetId && targetId.startsWith('#')) {
                event.preventDefault();
                const targetElement = document.querySelector(targetId);
                const offset = 80;
                const elementPosition = targetElement.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        };

        const navLinks = document.querySelectorAll('a[href^="#"]');
        navLinks.forEach(link => link.addEventListener('click', handleScroll));

        return () => {
            navLinks.forEach(link => link.removeEventListener('click', handleScroll));
        };
    }, []);

    return (
        <>
            <HeaderContent />
            <div className="section section-mat">
                <div className='container'>
                    <h2>OpenGL</h2>
                    <article>
                        <div className="text-center mb-4">
                            <img
                                src={require('../assets/images/opengl-logo.png')}
                                alt="OpenGL Logo"
                                className="img-fluid"
                                style={{ maxWidth: '400px' }}
                            />
                        </div>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="mb-4 text-center">Detalles Técnicos de OpenGL</h2>
                                    <h3>1. API de Bajo Nivel</h3>
                                    <p>
                                        OpenGL es una API de bajo nivel que proporciona una abstracción mínima del hardware gráfico. Esto permite un control detallado sobre la pipeline de gráficos, pero requiere manejar muchos detalles técnicos complejos, como la gestión de memoria y los estados del renderizado.
                                    </p>
                                    <h3>2. Pipeline de Gráficos</h3>
                                    <p>
                                        La pipeline de gráficos de OpenGL está compuesta por varias etapas, cada una responsable de transformar y procesar los datos geométricos para generar una imagen renderizada. A continuación, se describen las principales etapas:
                                    </p>
                                    <ul className="list-group mb-4">
                                        <li className="list-group-item">
                                            <strong>Procesamiento de Vértices:</strong> Esta etapa transforma las coordenadas de los vértices. Aquí se aplican las matrices de transformación para rotación, escalado y traslación. También se calculan efectos de iluminación iniciales en los vértices.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Primitivas y Rasterización:</strong> Una vez que los vértices han sido transformados, OpenGL los organiza en primitivas geométricas (puntos, líneas y triángulos). La rasterización convierte estas primitivas en fragmentos, que son potenciales píxeles en pantalla.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Procesamiento de Fragmentos:</strong> Los fragmentos son procesados para determinar su color, aplicación de texturas, iluminación por píxel y otras operaciones gráficas avanzadas.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Pruebas y Mezclas:</strong> Aquí se realizan las pruebas de profundidad (z-buffer), stencil y blending, para definir cómo los fragmentos interactúan con los píxeles ya presentes en el framebuffer.
                                        </li>
                                    </ul>
                                    <h3>3. Shaders y GLSL</h3>
                                    <p>
                                        Con OpenGL 2.0 y versiones posteriores, los shaders personalizados son programados mediante GLSL (OpenGL Shading Language). Estos pequeños programas se ejecutan directamente en la GPU y permiten controlar completamente las etapas de procesamiento de vértices y fragmentos:
                                    </p>
                                    <ul className="list-group mb-4">
                                        <li className="list-group-item">
                                            <strong>Vertex Shaders:</strong> Transforman los atributos de vértices, como la posición y las normales. También calculan las transformaciones de espacio de mundo y espacio de vista.
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Fragment Shaders:</strong> Determinan el color final de los fragmentos, permitiendo aplicar texturas, efectos de luz per-pixel y otras técnicas avanzadas de renderizado.
                                        </li>
                                    </ul>
                                    <h3>4. Contexto de OpenGL</h3>
                                    <p>
                                        Para usar OpenGL en cualquier aplicación, es necesario crear un "contexto de OpenGL". Este contexto representa todos los estados de OpenGL, incluyendo buffers, texturas, y programas de shaders, y debe ser creado por el sistema operativo o por bibliotecas de terceros como GLFW o SDL.
                                    </p>
                                    <h3>5. Extensibilidad y Multiplataforma</h3>
                                    <p>
                                        OpenGL es extensible, lo que permite que los fabricantes de hardware agreguen nuevas funcionalidades a través de extensiones antes de que sean estandarizadas. Además, OpenGL es una API multiplataforma, compatible con diferentes sistemas operativos (Windows, macOS, Linux) y dispositivos (PCs, móviles con OpenGL ES).
                                    </p>
                                    <h3>6. Rendimiento y Optimización</h3>
                                    <p>
                                        OpenGL permite aprovechar las características del hardware gráfico, lo que resulta en un alto rendimiento para aplicaciones gráficas. Para lograr un rendimiento óptimo, los desarrolladores deben tener en cuenta técnicas como el uso eficiente de buffers, reducción de cambios de estado y optimización del pipeline gráfico mediante la reducción de llamadas de renderizado innecesarias.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                    <div className='separator'></div>
                </div>
                <h2 className="mb-4 text-center">Shaders y Rendering Pipeline</h2>
                <br></br>
                <div>
                    <p>
                        No es tan sencillo como que la CPU reciba los comandos y ejecute alguna función en segundo plano.

                        Lo que ocurre en realidad es que pasa por la tarjeta gráfica o la GPU y, para que sea lo

                        más rápido posible, hace algo que llamamos <strong style={{color: 'blue'}}>pipelining</strong>.
                    </p>
                    <br></br>
                    <div className="container mt-5">
                        <h1 className="mb-4 text-center"><strong  style={{color: 'blue'}}>'Pipelining':</strong> Gráfico en OpenGL y GPU</h1>

                        <h2 className="mb-3">1. Pipeline Gráfico en OpenGL y GPU</h2>
                        <p>
                            El pipeline gráfico en OpenGL describe las etapas por las que pasan los datos geométricos, como los vértices y las texturas, para convertirse en píxeles visibles en la pantalla. Las GPU modernas siguen una pipeline gráfica programable, lo que significa que algunas de sus etapas pueden ser personalizadas mediante programas llamados <strong>shaders</strong>.
                        </p>

                        <h2 className="mb-3">2. Pipeline Gráfico Tradicional de OpenGL</h2>

                        <h3>2.1. Input Assembly (Ensamblaje de Entrada)</h3>
                        <p>
                            En esta etapa, los datos geométricos se ensamblan desde buffers de vértices en la memoria de la GPU. Los vértices incluyen datos como posiciones, colores y coordenadas de textura. Se define cómo deben ensamblarse los vértices en primitivas geométricas.
                        </p>

                        <h3>2.2. Vertex Shader (Sombreado de Vértices)</h3>
                        <p>
                            El <strong>Vertex Shader</strong> es la primera etapa programable. Aquí se procesan los vértices individualmente aplicando transformaciones geométricas, calculando la iluminación local y transformando las coordenadas de los vértices al espacio de pantalla.
                        </p>

                        <h3>2.3. Tessellation (Teselación) (Opcional)</h3>
                        <p>
                            La teselación subdivide primitivas geométricas en otras más pequeñas para aumentar el detalle sin modificar los datos originales. Se usa en escenarios como superficies detalladas en objetos complejos.
                        </p>

                        <h3>2.4. Geometry Shader (Sombreado Geométrico) (Opcional)</h3>
                        <p>
                            El <strong>Geometry Shader</strong> recibe primitivas completas en lugar de vértices individuales. Permite generar nuevas primitivas dinámicamente o modificar las existentes, lo que resulta útil para generar geometría en tiempo real.
                        </p>

                        <h3>2.5. Rasterization (Rasterización)</h3>
                        <p>
                            En esta etapa fija, las primitivas geométricas se convierten en <strong>fragmentos</strong>, que son unidades que potencialmente se convertirán en píxeles. Se realiza la interpolación de vértices y texturas.
                        </p>

                        <h3>2.6. Fragment Shader (Sombreado de Fragmentos)</h3>
                        <p>
                            El <strong>Fragment Shader</strong> es una etapa programable que opera sobre cada fragmento generado en la rasterización. Aquí se calcula el color final y se aplican texturas, iluminación per-pixel y efectos de post-procesamiento.
                        </p>

                        <h3>2.7. Depth and Stencil Testing (Pruebas de Profundidad y Stencil)</h3>
                        <p>
                            Se realizan pruebas para decidir si los fragmentos deben dibujarse en el framebuffer. La <strong>prueba de profundidad</strong> asegura que solo los fragmentos más cercanos a la cámara se dibujen, y la <strong>prueba de stencil</strong> permite crear máscaras complejas.
                        </p>

                        <h3>2.8. Blending (Mezclado)</h3>
                        <p>
                            En esta etapa, el color de cada fragmento se combina con los píxeles existentes en el framebuffer. Esto permite crear efectos de transparencia y anti-aliasing.
                        </p>

                        <h2 className="mb-3">3. Optimización del Pipeline Gráfico</h2>

                        <h3>3.1. Vertex Buffer Objects (VBOs) y Element Buffer Objects (EBOs)</h3>
                        <p>
                            Los <strong>VBOs</strong> permiten almacenar datos de vértices en la memoria de la GPU, mientras que los <strong>EBOs</strong> permiten reutilizar vértices mediante índices, mejorando la eficiencia al evitar duplicación de datos.
                        </p>

                        <h3>3.2. Framebuffer Objects (FBOs)</h3>
                        <p>
                            Los <strong>FBOs</strong> permiten renderizar fuera de pantalla, lo que es útil para realizar efectos de post-procesamiento como sombras, reflejos y desenfoques.
                        </p>

                        <h3>3.3. Texturas y Samplers</h3>
                        <p>
                            El uso de texturas optimizadas mediante técnicas como el <strong>mipmapping</strong> y el filtrado anisotrópico mejora tanto el rendimiento como la calidad visual.
                        </p>

                        <h3>3.4. Shader Optimization</h3>
                        <p>
                            La optimización de shaders es crucial para mejorar el rendimiento. Minimizar el uso de bucles y ramas en los shaders es importante para maximizar la paralelización en la GPU.
                        </p>

                        <h2 className="mb-3">4. Pipelining Gráfico Moderno con Vulkan y DirectX 12</h2>
                        <p>
                            <strong>Vulkan</strong> y <strong>DirectX 12</strong> son APIs de bajo nivel que ofrecen un control más detallado sobre el hardware gráfico, permitiendo una gestión más eficiente de la sincronización, la memoria de la GPU y múltiples sub-pipelines, eliminando el overhead de OpenGL.
                        </p>

                        <h2 className="mb-3">Conclusión</h2>
                        <p>
                            El <strong>pipelining gráfico en OpenGL</strong> es esencial para renderizar gráficos 2D y 3D de manera eficiente. La capacidad de programar shaders y la paralelización masiva en la GPU permiten optimizar el procesamiento gráfico, especialmente en aplicaciones como videojuegos, simulaciones y visualización científica. Cada etapa del pipeline gráfico puede optimizarse para obtener el máximo rendimiento.
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Openg;
